<template>
  <div class="inner-section a11-wrapper">
    <breadcumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">তৈরী চায়ের রেসিডিউ মাত্রা বিশ্লেষণপূর্বক প্রয়োজনীয় পরামর্শ প্রদান</h4>
      </template>
      <template v-slot:body>
        <div class="apps-header">
          <p>স্মারক নং: ২১২৫৬৫</p>
          <p>তারিখ: ২১/১২/২০২২</p>
        </div>
        <div class="apps-subject-wrapper mb-4">
          <p>পরিচালক</p>
          <p>বাংলাদেশ চা গবেষণা ইনস্টিটিউট</p>
          <p>শ্রীমঙ্গল-৩২১০</p>
          <p>জেলা- মৌলভীবাজার</p>
        </div>
        <p class="mb-4">বিষয়: <span style="border-bottom: 1px dashed #444;min-width: 130px;display: inline-block;"></span> পূর্বক প্রয়োজনীয় পরামর্শ প্রদান।</p>
        <div class="cover-letter-wrapper">
          <p class="mb-2">প্রিয় মহোদয়,</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;উপর্যুক্ত বিষয়ে, <span style="border-bottom: 1px dashed #444;min-width: 130px;display: inline-block;"></span> চা বাগানের কারখানায় তৈরীকৃত চায়ের পেস্টিসাইড রেসিডিউ মাত্ৰা বিশ্লেষণপূর্বক প্রয়োজনীয় সুপারিশসহ পরামর্শ প্রদানের জন্য নিন্মোক্তভাবে তৈরী চায়ের নমুনা প্রেরণ করা হলো। প্রয়োজনীয় ব্যবস্থা গ্রহনের জন্য আপনাকে বিনীত অনুরোধ জানাচ্ছি।</p>
          <div class="table-responsive">
            <table class="table table-bordered text-center mt-4">
              <thead>
                <tr>
                  <th>নমুনা নং</th>
                  <th>তৈরী চায়ের গ্রেড</th>
                  <th>সংখ্যা</th>
                  <th>মন্তব্য</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>----</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="apps-footer">
          <p>ধন্যবাদান্তে</p>
          <p>আপনার বিশ্বস্ত,</p>
        </div>
      </template>
    </body-card>
  </div>
</template>
<script>
export default {

}
</script>
